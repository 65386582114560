<template>
  <div class="container">
    <BreadCrumb
        :links="[
            {
              name: 'Fabric Translate'
            },
            {
              name: 'Fabric Translate Application',
              params: {
                auuid: $route.params.auuid
              }
            }
          ]"
    />
    <TracedPensionExtractedTextList />
  </div>
</template>
<script>
import TracedPensionExtractedTextList from "@/components/ml-operations/TracedPensionExtractedTextList";
import { mapGetters } from "vuex";
export default {
  name: "MLApplication",
  components: {
    TracedPensionExtractedTextList
  },
  data: () => ({
    view: "ApplicationExtractedTextList"
  }),
  computed: {
    ...mapGetters(["getApplicationFullName"])
  }
};
</script>
