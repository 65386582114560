<template>
  <div>
    <div v-if="tracedPensionCompLength" class="table-responsive">
      <table class="table table-hover text-left">
        <thead>
        <tr>
          <th scope="col">id</th>
          <th scope="col">
            Provider/Scheme
          </th>
          <th scope="col">Policy&nbsp;Number</th>
          <th scope="col">Employer</th>
          <th v-if="showKnownPensionSwitch && !minCols" scope="col">
            Known&nbsp;Pension&nbsp;(&nbsp;green&nbsp;)
          </th>
          <th>Complete Reason</th>
          <th class="text-left" scope="col">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(tp, index) in tracedPensionComp"
            :key="index"
            class="table-row"
            :class="[parentTrace(tp.multi_loa)]"
        >
          <td @click="handlePension(tp)">
            <PopOver :content="tp.uuid">
              <span class="text-subdued">{{ tp.id }}</span>&nbsp;&nbsp;
            </PopOver>
          </td>
          <td class="row" scope="row">
            <div class="d-flex">
              <div v-if="tp.multi_loa === 1">
                <span class="badge badge-warning" style="display: math;">Multi LOA</span>
                &nbsp;&nbsp;
              </div>
              <div v-if="tp.parent_id">
                <PopOver :content="'Parent id:' + tp.parent_id">
                  <span class="badge badge-warning" style="display: math;">{{ tp.parent_id }}</span>&nbsp;&nbsp;
                </PopOver>
              </div>
              <PopOver class="mr-2" v-if="tp?.jobs_deleted?.length > 0" content="Translate&nbsp;job(s)&nbsp;deleted...">
                <InfoModal ref="previousName">
                  <template #btn-text>
                    <icon :data="robot" width="20" dir="up" color="red"/>
                  </template>
                  <template #title>
                    Translate job(s) deleted
                  </template>
                  <template #content>
                    <div v-for='(job, index) in tp?.jobs_deleted' :key='index'>
                      <CopyText :value="job?.translate_id" class="mb-3"/>
                    </div>
                  </template>
                </InfoModal>
              </PopOver>
              <span class="link-text" @click="handlePension(tp)">
                {{ getProviderName(tp) }}
              </span>
              <div v-if="hasEvents(tp)" class="d-inline-block">
                <PopOver content="Event(s) received...">
                  &nbsp;&nbsp;<icon :data="event" color="orange" dir="up" height="20" width="20"/>
                </PopOver>
              </div>
            </div>
          </td>
          <td @click="handlePension(tp)">
            {{ getPolicyNo(tp) }}
          </td>
          <td @click="handlePension(tp)">
            {{ getEmployer(tp) }}
          </td>
          <td
              v-if="showKnownPensionSwitch && !minCols"
          >
            <input
                :id="`switch_${index}`"
                :checked="tp.pension_known"
                class="toggleSwitch"
                type="checkbox"
                @change="handleToggleChange($event, tp)"
            /><label :for="`switch_${index}`" class="toggleLabel"></label>
          </td>
          <td>
            <div v-if="tp.status === 'complete'">{{ tp.closed_reason || "Unspecified" }}</div>
          </td>
          <td class="text-left">
            <StatusUpdate
                :ref="`statusUpdateComponent_${tp.id}`"
                :entity="tp"
                :inactive="statusInactive"
                :with-title="false"
                @status-changed="handleStatus($event, tp.id)"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="mt-3">No records found!</div>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import StatusUpdate from "@/components/form/StatusUpdate";
import event from "@fa/solid/pencil-alt.svg";
import robot from "@fa/solid/robot.svg";
import CopyText from "@/components/misc/CopyText.vue";

export default {
  name: "TracedPensionList",
  props: {
    tracedPensionProp: {
      default: null
    },
    linksProp: {
      default: null
    },
    metaProp: {
      default: null
    },
    showKnownPensionSwitch: {
      default: false
    },
    minCols: {
      type: Boolean,
      default: false
    },
    statusInactive: {
      type: Boolean,
      default: false
    }
  },
  emits: ["handle-pension"],
  components: {
    CopyText,
    StatusUpdate
  },
  data: () => ({
    missingText: "Not available",
    tracedPension: [],
    links: [],
    meta: [],
    event,
    robot
  }),
  watch: {
    getTracedPension(n) {
      this.tracedPension = n;
    },
    tracedPensionProp(n) {
      this.tracedPension = n;
    },
    linksProp(n) {
      this.links = n;
    },
    metaProp(n) {
      this.meta = n;
    }
  },
  computed: {
    ...mapGetters(["getStatusTPValues", "getApplication", "getStatusClosedReason"]),
    getTracedPension() {
      console.log(this.getApplication);
      if (typeof this.getApplication?.traced_pension !== "undefined") {
        return this.getApplication?.traced_pension;
      } else {
        return [];
      }
    },
    tracedPensionComp() {
      return this.tracedPension;
    },
    tracedPensionCompLength() {
      return this.tracedPension.length > 0;
    },
  },
  methods: {
    ...mapActions(["loadTracedPension", "updateTracedPension"]),
    ...mapMutations(["setTracedPension"]),
    parentTrace(multiLoa) {
      return {
        '-parent-trace': multiLoa === 1
      }
    },
    hasEvents(tp) {
      return tp?.event_caching?.length > 0;
    },
    async handleStatus(param, id) {
      let tempParams = {};
      let provider = {
        id,
        status: param.status,
        closed_reason: param.closed_reason
      };
      tempParams.pensions = [];
      tempParams.pensions.push({
        provider
      });
      tempParams.updatePensionForm = true;

      await this.updateTracedPension({tempParams}).catch(err =>
          console.log(err)
      );

      await this.$nextTick(() => {
        this.$refs["statusUpdateComponent_" + id][0].edit = false;
        this.$refs["statusUpdateComponent_" + id][0].updating = false;
      });
    },
    getProviderParent(tp) {
      return tp?.current_provider?.current_parent || null;
    },
    getProviderName(tp) {
      return tp?.current_provider?.name || tp?.pension_provider[tp?.pension_provider.length - 1]?.name || this.missingText;
    },
    getPolicyNo(tp) {
      return tp.policy_number || this.missingText;
    },
    getEmployer(tp) {
      return tp.current_provider?.employer?.name || tp.employment[0]?.name_of_employer || this.missingText;
    },
    async handleToggleChange(e, tp) {
      let tempParams = {};
      tempParams.pensions = [];
      let provider = {
        id: tp.id,
        pension_known: e.target.checked ? 1 : 0
      };
      tempParams.pensions.push({
        provider
      });
      await this.updateTracedPension({tempParams, update: false}).catch(
          err => {
            this.setNotification({
              show: true,
              type: "error",
              message: "Pension trace error!",
              content: err.response.data.error
            });
          }
      );
    },
    async handlePension(tracedPension) {
      this.setTracedPension(tracedPension);
      this.$emit("handle-pension", {
        auuid: tracedPension.app_uuid || null,
        tpuuid: tracedPension.uuid
      });
    }
  },
  async beforeMount() {
    if (!this.tracedPensionProp) {
      this.tracedPension = this.getTracedPension;
    }
  }
};
</script>
<style lang="scss" scoped></style>
