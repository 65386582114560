<template>
<!--  <div class="container">-->
    <div class="card">
      <div class="card-body">
        <TracedPensionList
          :traced-pension-prop="tracedPensionComp"
          :links-prop="links"
          :meta-prop="meta"
          :status-inactive="true"
          @handle-pension="handleSelect"
        />
      </div>
    </div>
<!--  </div>-->
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import TracedPensionList from "@/components/lists/TracedPensionList";
export default {
  name: "TracedPensionExtractedTextList",
  components: {
    TracedPensionList
  },
  emits: ["selected"],
  data: () => ({
    tracedPension: [],
    links: [],
    meta: []
  }),
  computed: {
    tracedPensionComp() {
      return this.tracedPension;
    },
    ...mapGetters(["getMLPageCount"])
  },
  methods: {
    ...mapMutations([
      "setExtractedText",
      "setApplicationUuid",
      "setLoading",
      "setMLTextBlocks",
      "setMLTextEntities",
      "setMLPages",
      "setTracedPensionUuid"
    ]),
    ...mapActions([
      "getTracedPensionWithMLDocs",
      "getMLData",
      "getExtractedText"
    ]),
    async handleSelect({ tpuuid }) {
      this.$router.push({
        name: "Fabric Translate Extracted Pages",
        params: {
          auuid: this.$route.params.auuid,
          tpuuid: tpuuid
        }
      });
    }
  },
  async beforeMount() {
    let response = await this.getTracedPensionWithMLDocs(
      this.$route.params.auuid
    ).catch(err => console.log(err));
    this.tracedPension = response.data.data;
    this.links = response.data.links;
    this.meta = response.data.meta || null;
  }
};
</script>
